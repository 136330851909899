import React, { useContext, useState } from "react";
import { formatDistanceStrict } from 'date-fns'
import { pt } from 'date-fns/locale'
import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../App";

const Article = ({ article }) => {
    const { time } = useContext(AppContext);
    const [ showMore, setShowMore ] = useState(false);

    if (!article) { 
        return null;
    }

    // TODO detect mobile long press
    const onMouseDown = (event, articleId) => {
        if (event.button === 0 || event.button === 1 || event.button === 2) {
            fetch("/api/url/" + articleId + "/" + event.button, {
                method: "POST"
            });
        }
    }

    return (
        <Button href={article.link} target="_blank" onMouseDown={(event) => onMouseDown(event, article.id)} sx={{ p: 0, textTransform: 'none', display: 'flex' }}>
            <Paper variant="outlined" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', '&:hover': { backgroundColor: '#f7f7f7', borderRadius: 1 }}} >
                    {article.image &&
                        <Box sx={{
                            flex: '0 1 100px',
                            backgroundImage: `url("${article.image}")`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            borderRadius: 1,
                            maxHeight: 75,
                            m: 1
                        }} />
                    }

                    {!article.image &&
                        <Box sx={{
                            flex: '0 1 100px',
                            backgroundColor: '#f7f7f7',
                            borderRadius: 1,
                            maxHeight: 75,
                            m: 1
                        }} />
                    }

                    <Box sx={{ flex: '1 1', px: 1, py: 1 }}>
                        <Box>
                            <Box sx={{
                                color: 'text.primary',
                                fontFamily: "'Merriweather', serif",
                                fontSize: [14, 15, 16],
                                fontWeight: 'lighter',
                                lineHeight: '1.5',
                                mb: 0.5
                            }}>
                                {article.title}
                            </Box>
                            <Box
                                sx={{
                                    color: 'text.secondary',
                                    fontWeight: 'lighter',
                                    lineHeight: '1.5',
                                    fontSize: [10, 12],
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                            }}
                            >
                                {article.source_logo &&
                                <Box
                                    component={'img'}
                                    sx={{
                                        height: [10, 12],
                                        verticalAlign: 'middle',
                                        mr: '4px',
                                        mt: [0, '-1px']
                                    }}
                                    src={article.source_logo}
                                />
                                }
                                {article.source_name}
                                <Box sx={{ display: 'inline', fontSize: [8, 10], mx: 0.75 }}>•</Box>
                                Há {formatDistanceStrict(new Date(article.date), time, { locale: pt })}
                                {/* {article.author &&
                                <>
                                    <Box sx={{ display: 'inline', fontSize: [8, 10], mx: 0.75 }}>•</Box>
                                    {article.author}
                                </>
                                } */}
                            </Box>
                        </Box>
                        {article.related &&
                        <Button onClick={(ev) => { setShowMore(!showMore); ev.preventDefault(); return false; }} sx={{ px: 1, py: 0.25, textTransform: 'none' }}>
                            <Box sx={{ fontSize: [10, 10], fontWeight: 'lighter' }}>+{article.related.length} artigos relacionados</Box>
                        </Button>
                        }
                    </Box>
                </Box>
                {article.related && showMore &&
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', px: 1, pb: 1 }}>
                    {article.related.slice(1).map(article =>
                        <Button key={article.id} href={article.link} target="_blank" onMouseDown={(event) => onMouseDown(event, article.id)} sx={{ p: 0, textTransform: 'none', display: 'flex' }}>
                            <Box sx={{ py: 0.7, px: 1 }}>
                                <Box sx={{
                                    color: 'text.primary',
                                    fontFamily: "'Merriweather', serif",
                                    fontSize: [12, 13, 14],
                                    fontWeight: 'lighter',
                                    lineHeight: '1.2'
                                }}>
                                    {article.title}
                                </Box>
                                <Box sx={{
                                    color: 'text.secondary',
                                    fontWeight: 'lighter',
                                    lineHeight: '1.5',
                                    fontSize: [10, 10],
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    mt: 0.5
                                }}
                                >
                                    {article.source_logo &&
                                    <Box
                                        component={'img'}
                                        sx={{
                                            height: [8, 10],
                                            verticalAlign: 'middle',
                                            mr: '4px',
                                            mt: [0, '-1px']
                                        }}
                                        src={article.source_logo}
                                    />
                                    }
                                    {article.source_name}
                                    <Box sx={{ display: 'inline', fontSize: [8, 10], mx: 0.75 }}>•</Box>
                                    Há {formatDistanceStrict(new Date(article.date), time, { locale: pt })}
                                    {/* {article.author &&
                                    <>
                                        <Box sx={{ display: 'inline', fontSize: [8, 10], mx: 0.75 }}>•</Box>
                                        {article.author}
                                    </>
                                    } */}
                                </Box>
                            </Box>
                        </Button>
                    )}
                </Box>
                }
            </Paper>
        </Button>
    );
}

export default Article;
