import React, { useContext } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { format } from 'date-fns'
import { pt } from 'date-fns/locale'

import { Box } from "@mui/system";
import { AppContext } from "../App";
import { Button } from "@mui/material";

const ArticleTimeline = () => {
    const { articles } = useContext(AppContext);

    var currentDate;

    const maybePrintDate = (article) => {
        const date = format(new Date(article.date), "d MMM ", { locale: pt });

        if (currentDate !== date) {
            currentDate = date;
            return <>{date}<br/></>;
        } else {
            return null;
        }
    };

    // TODO detect mobile long press
    const onMouseDown = (event, articleId) => {
        if (event.button === 0 || event.button === 1 || event.button === 2) {
            fetch("/api/url/" + articleId + "/" + event.button, {
                method: "POST"
            });
        }
    }

    return (
        <Timeline sx={{ p: 0 }}>
            { articles && articles.map(article =>
                <TimelineItem sx={{ minHeight: '45px' }}>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0', pl: 0, maxWidth: 55, fontSize: 12 }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        {maybePrintDate(article)}
                        {format(new Date(article.date), "HH:mm", { locale: pt })}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ width: '1px' }} />
                        <TimelineDot sx={{ p: 0, border: 0, my: '4px' }}>
                            {article.source_logo &&
                            <Box
                                component={'img'}
                                sx={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12
                                }}
                                src={article.source_logo}
                            />
                            }
                            {!article.source_logo &&
                                <Box sx={{
                                    backgroundColor: '#f7f7f7',
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12
                                }} />
                            }
                        </TimelineDot>
                        <TimelineConnector sx={{ width: '1px' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ m: 'auto 0', py: 0 }}>
                        <Button
                            key={article.id}
                            href={article.link}
                            target="_blank"
                            onMouseDown={(event) => onMouseDown(event, article.id)}
                            sx={{
                                color: 'text.primary',
                                fontFamily: "'Merriweather', serif",
                                fontSize: [14, 15, 16],
                                fontWeight: 'lighter',
                                lineHeight: '1.5',
                                textTransform: 'none',
                                ml: -1
                            }}>
                            {article.title}
                        </Button>
                    </TimelineContent>
                </TimelineItem>
            )}
        </Timeline>
    );
}

export default ArticleTimeline;
