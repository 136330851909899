import React, { useContext } from "react";
import { Container, Paper, Tab, Tabs } from "@mui/material";
import { AppContext } from "../App";

const Menu = () => {
    const { type, setType } = useContext(AppContext);

    const height = 40;

    return (
        <Paper variant="" square sx={{ borderTop: 1, borderBottom: 1, borderColor: 'grey.500' }}>
            <Container sx={{ marginBottom: '-1.5px' }}>
                <Tabs
                    value={type}
                    onChange={(ev, value) => setType(value)}
                    variant="standard"
                    aria-label="scrollable force tabs example"
                    style={{ minHeight: height, height: height }}
                    centered
                >
                    <Tab value="destaque" label="Destaques" sx={{ minHeight: height, height: height, fontSize: '12px' }} />
                    <Tab value="recente" label="Últimas" sx={{ minHeight: height, height: height, fontSize: '12px' }} />
                    {/* <Tab value="cronologia" label="Cronologia" sx={{ minHeight: height, height: height, fontSize: '12px' }} /> */}
                </Tabs>
            </Container>
        </Paper>
    );
}

export default Menu;
