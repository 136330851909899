import React, { useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../App";
import LazyLoad, { forceCheck } from 'react-lazyload';
import Article from "./Article";
import ArticleTimeline from "./ArticleTimeline";

const Articles = () => {
    const { articles, loading, type, style, limit, next } = useContext(AppContext);
    const [ hasMore, setHasMore ] = useState(true);

    // trigger check on article filter
    useEffect(() => {
        forceCheck();
        setHasMore(articles && articles.length >= limit);
    }, [articles, limit, style]);

    if (!articles) { 
        return null;
    }

    return (
        <Container>
            {type === "cronologia" &&
            <ArticleTimeline />
            }
            {type !== "cronologia" &&
            <Grid container rowSpacing={style === 'inline' ? 2 : 2} sx={{ mt: style === 'inline' ? 0 : 0, mb: 4 }}>
            { articles && articles.map(article =>
                <Grid key={article.id} item xs={12}>
                    <LazyLoad height={type === "recente" ? 64 : 89} offset={100}>
                        <Article article={article} />
                    </LazyLoad>
                </Grid>
            )}
            </Grid>
            }
            {(!articles || articles.length === 0) && !loading &&
            <Box
                sx={{
                    color: 'text.primary',
                    fontWeight: 'lighter',
                    display: 'inline',
                    fontSize: [14, 16],
                    mx: 1.5,
                    mb: 4
                }}
            >Não foram encontradas notícias.</Box>
            }
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                {loading &&
                    <CircularProgress color="primary" />
                }
                {!loading && hasMore &&
                    <Button variant="contained" color="primary" onClick={next}>
                        Mostrar mais notícias
                    </Button>
                }
            </Box>
        </Container>
    );
}


export default Articles;
