import React, { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryParamProvider, StringParam, useQueryParam } from 'use-query-params';
import { CssBaseline, Toolbar } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Header from "./section/Header";
import Menu from "./section/Menu2";
import Articles from "./section/Articles";
// import Sidebar from "./section/Sidebar";
import Footer from "./section/Footer";

export const AppContext = createContext({});

const pageSize = 30;

const AppRouter = () => {
    const [articles, setArticles] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useQueryParam("q", StringParam);
    const [location, setLocation] = useLocationPath();
    const [type = "destaque", setType] = useQueryParam("t", StringParam);
    const [style, setStyle] = useState("default");
    const [limit, setLimit] = useState(pageSize);
    const [time, setTime] = useState(new Date());
    
    const fetchJson = (url, setter) => {
        setLoading(true);

        setTimeout(async () => {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                console.error("An error has occured.");
                setLoading(false);
                return;
            }

            try {
                const json = await response.json();
                setter(json);
            } catch (error) {
                console.error("An error has occured.", error);
            } finally {
                setLoading(false);
            }
        }, 500);
    };

    useEffect(() => {
        setArticles([]);
        setLimit(pageSize);
    }, [search, location]);
    
    useEffect(() => {
        const parts = [];

        if (type) {
            parts.push(`t=${type}`);
        }

        if (search) {
            parts.push(`q=${search}`);
        }

        if (location) {
            parts.push(`l=${location}`);
        }

        parts.push(`c=${limit}`)

        fetchJson(`/api/article?${parts.join("&")}`, setArticles);
    }, [type, search, location, limit]);

    useEffect(() => {
        setArticles([]);
    }, [type]);

    // clock every 15s to update dates
    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 15000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    // global state store
    const store = {
        time: time,
        type: type,
        setType: setType,
        search: search,
        setSearch: setSearch,
        location: location,
        setLocation: setLocation,
        articles: articles,
        loading: loading,
        limit: limit,
        style: style,
        setStyle: setStyle,
        next: () => setLimit(limit + pageSize)
    };

    return (
        <AppContext.Provider value={store}>
            <CssBaseline />
            <Header />
            <Switch>
                <Route exact path="/:district?">
                    <Toolbar />
                    <Menu />
                    {/* <Container>
                        <Grid container spacing={0}>
                            <Grid item xs={9}>
                                <Articles />
                            </Grid>
                            <Grid item xs={3}>
                                <Sidebar />
                            </Grid>
                        </Grid>
                    </Container> */}
                    <Articles />
                </Route>
            </Switch>
            <Footer />
        </AppContext.Provider>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            contrastText: '#FFFFFF',
        }
    }
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fbfbfb',
        minHeight: '100vh'
    }
}));

const AppTheme = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={classes.root}>
                <AppRouter />
            </div>
        </ThemeProvider>
    ); 
};

const App = () => {
    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                <AppTheme />
            </QueryParamProvider>
        </Router>
    ); 
};

export const useLocationPath = () => {
    const location = useLocation();
    const history = useHistory();

    const [path, setPath] = useState(() => {
        if (!location.pathname || location.pathname === "/") {
            return undefined;
        } else {
            return location.pathname.substring(1);
        }
    });
  
    useEffect(() => {   
        if (!location.pathname || location.pathname === "/") {
            setPath();
        } else {
            setPath(location.pathname.substring(1));
        }
    }, [location.pathname]);

    const onSetPath = useCallback(
        (newPath) => history.push(`/${newPath || ""}${location.search}`),
        [history, location.search]
    );
  
    return [path, onSetPath];
}

export default App;
