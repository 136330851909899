import React from "react";
import { AppBar, Toolbar, IconButton, Container, Button } from '@mui/material';
// import { styled, alpha } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
// import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search'
// import { AppContext } from "../App";

const Header = () => {
    // const { search, setSearch } = useContext(AppContext);

    return (
        <AppBar color="inherit" elevation={0} sx={{ borderBottom: 1, borderColor: 'grey.500' }}>
            <Container>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <IconButton edge="start" color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Button color="inherit" style={{ padding: 'auto', fontFamily: "'Merriweather', serif", fontSize: '1.5rem', textAlign: 'center', alignItems: 'baseline' }} onClick={() => window.location.reload()}>
                        <span>DIRETO</span>
                        <span style={{ fontSize: '1rem' }}>.PT</span>
                    </Button>
                    <IconButton edge="start" color="inherit">
                        <SearchIcon />
                    </IconButton>
                    {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Procurar…"
                            value={search}
                            onChange={(event) => setSearch(event.target.value || undefined)}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     marginLeft: theme.spacing(1),
//     width: 'auto'
// }));
  
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));
  
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         width: 'calc(23vw)',
//         '&:focus': {
//             width: 'calc(30vw)',
//         }
//     },
// }));

export default Header;
