import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Container } from '@mui/material';
import { Box } from "@mui/system";

const Footer = () => {
    return (
        <Box sx={{ p: 3, mt: 'auto', backgroundColor: '#F3F3F3', textAlign: 'center' }}>
            <Container>
                <Box sx={{ display: 'block' }}>
                    <Box sx={{ display: 'inline' }}>Copyright © Bitstream 2022</Box>
                    <Box sx={{ mx: 2, display: 'inline'  }}>•</Box>
                    <Box sx={{ display: 'inline' }}>
                        <Link to="/privacidade" sx={{ color: 'inherit' }}>Política de Privacidade</Link>
                    </Box>
                    <Box sx={{ mx: 2, display: 'inline' }}>•</Box>
                    <Box sx={{ display: 'inline' }}>
                        <Link to="/termos" sx={{ color: 'inherit' }}>Termos e Condições</Link>
                    </Box>
                </Box>
            </Container>
      </Box>
    );
}

export default withRouter(Footer);
